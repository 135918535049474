/**
 * This Layout is needed for Starter Kit.
 */
import { Personalize, Qualtrics, QuantumMetrics } from '@hkexpressairwayslimited/ui';
import { Field, HTMLLink, LayoutServiceData, Placeholder } from '@sitecore-jss/sitecore-jss-nextjs';
import parse from 'html-react-parser';
import { envConfig } from 'lib/services/env';
import Head from 'next/head';
import { useMemo } from 'react';
import sanitizeHtml from 'sanitize-html';
import Scripts from 'src/Scripts';
import config from 'temp/config';

// Prefix public assets with a public URL to enable compatibility with Sitecore Experience Editor.
// If you're not supporting the Experience Editor, you can remove this.
const publicUrl = config.publicUrl;

interface LayoutProps {
  layoutData: LayoutServiceData;
  headLinks: HTMLLink[];
}

export interface RouteFields {
  [key: string]: unknown;
  pageTitle?: Field<string>;
  metaKeywords?: Field<string>;
  metaDescription?: Field<string>;
  metaCustom?: Field<string>;
  canonicalURL?: Field<string>;
  openGraphTitle?: Field<string>;
  openGraphTypes?: Field<string>;
  openGraphUrl?: Field<string>;
  openGraphImage?: Field<string>;
}

const Layout = ({ layoutData, headLinks }: LayoutProps): JSX.Element => {
  const { route, context } = layoutData.sitecore;
  const fields = route?.fields as RouteFields;
  const isPageEditing = layoutData.sitecore.context.pageEditing;
  const mainClassPageEditing = isPageEditing ? 'editing-mode' : 'prod-mode';
  const parsedMetaCustom = useMemo(
    () =>
      fields?.metaCustom?.value &&
      parse(
        sanitizeHtml(fields.metaCustom.value, {
          allowedTags: ['meta'],
          allowedAttributes: false,
        })
      ),
    [fields?.metaCustom?.value]
  );
  const pagePathUrl = useMemo(
    () => new URL(`${envConfig.publicUrl}${context.itemPath}`),
    [context.itemPath]
  );
  const pagePath = useMemo(
    () => `${pagePathUrl.origin}${pagePathUrl.pathname.replace(/\/$/, '')}`,
    [pagePathUrl]
  );
  const canonicalUrl = useMemo(
    () => new URL(fields?.canonicalURL?.value || pagePath),
    [fields?.canonicalURL?.value, pagePath]
  );
  const siteName = 'HK Express';
  const robots = useMemo(
    () => [envConfig.disableSearchIndexing ? 'noindex' : 'index'].join(', '),
    []
  );

  return (
    <>
      <Scripts />
      <Head>
        <title>
          {fields?.pageTitle?.value ? `${fields?.pageTitle?.value} - ${siteName}` : siteName}
        </title>
        <link rel="icon" href="/favicon.ico" />
        {fields?.metaKeywords?.value && (
          <meta name="keywords" content={fields.metaKeywords.value} />
        )}
        {fields?.metaDescription?.value && (
          <meta name="description" content={fields.metaDescription.value} />
        )}

        {robots && <meta name="robots" content={robots} />}

        {parsedMetaCustom}

        <link rel="canonical" href={canonicalUrl.href.replace(/\/$/, '')} />
        {envConfig.languageList.map((lang) => (
          <link
            rel="alternate"
            key={lang}
            hrefLang={lang}
            href={`${canonicalUrl.origin}${lang !== envConfig.defaultLanguage ? `/${lang}` : ''}${canonicalUrl.pathname}`}
          />
        ))}

        {fields?.openGraphTitle?.value && (
          <meta property="og:title" content={fields.openGraphTitle.value} />
        )}
        <meta property="og:type" content={fields?.openGraphTypes?.value || 'website'} />
        <meta property="og:site_name" content={siteName} />
        <meta
          property="og:url"
          content={(fields?.openGraphUrl?.value || canonicalUrl.href).replace(/\/$/, '')}
        />
        <meta
          property="og:image"
          content={
            fields?.openGraphImage?.value ||
            'https://static.hkexpress.com/media/1086/og-thumbnails_1200-425.jpg'
          }
        />
        {layoutData.sitecore.context.language && (
          <meta property="og:locale" content={layoutData.sitecore.context.language} />
        )}

        {headLinks.map((headLink) => (
          <link rel={headLink.rel} key={headLink.href} href={headLink.href} />
        ))}
      </Head>
      <Qualtrics url={envConfig.qualtricsUrl} />
      <QuantumMetrics />
      <Personalize />

      {/* root placeholder for the app, which we add components to using route data */}
      <div className={mainClassPageEditing}>
        <div id="jss-main">{route && <Placeholder name="jss-main" rendering={route} />}</div>
      </div>
    </>
  );
};

export default Layout;
