import {
  Booking as BookComponent,
  Box,
  SearchingDetail,
  convertSearchParams,
} from '@hkexpressairwayslimited/ui';
import { withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import { format } from 'date-fns';
import { ComponentProps } from 'lib/component-props';
import { endpoints, fetchAPI } from 'lib/services/api';
import { envConfig } from 'lib/services/env';
import { useTransContent } from 'lib/transContent';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

type BookingProps = {
  params: {
    campaignCode?: string;
  };
} & ComponentProps;

const getFlightRoute = async () => {
  const result = await fetchAPI(endpoints.flightRouteMapping, {
    method: 'GET',
    cache: 'no-store',
    headers: { 'Content-Type': 'application/json' },
  });
  return result;
};

const Booking = ({ params }: BookingProps): JSX.Element => {
  const { t } = useTransContent();
  const router = useRouter();
  const [flightRouteOptions, setFlightRouteOptions] = useState<unknown>(null);
  useEffect(() => {
    getFlightRoute().then((response) => {
      setFlightRouteOptions(response);
    });
  }, []);
  const handleBookingTripSubmit = (data: SearchingDetail) => {
    const query = convertSearchParams(data);

    router.push(`${envConfig.bookingHost}/${router.locale}/flight-booking/select?${query}`);
  };
  const handleManageMyBookingSubmit = (data: any) => {
    const { BookingRef, FirstName, LastName } = data.data;
    sessionStorage.setItem(
      'tripParams',
      JSON.stringify({
        bookingRef: BookingRef,
        firstName: FirstName,
        lastName: LastName,
      })
    );
    const params = new URLSearchParams({
      bookingRef: BookingRef,
      firstName: FirstName,
      lastName: LastName,
    });
    router.push(`${envConfig.bookingHost}/manage-my-booking/my-trips/${BookingRef}?${params}`);
  };
  const handleManageMyBookingLink = () => {
    router.push(`${envConfig.bookingHost}/manage-my-booking/my-trips`);
  };
  const handleCheckInSubmit = (data: any) => {
    const { BookingRef, FirstName, LastName } = data.data;

    sessionStorage.setItem(
      'tripParams',
      JSON.stringify({
        bookingRef: BookingRef,
        firstName: FirstName,
        lastName: LastName,
      })
    );
    const params = new URLSearchParams({
      bookingRef: BookingRef,
      firstName: FirstName,
      lastName: LastName,
    });
    router.push(`${envConfig.bookingHost}/manage-my-booking/my-trips/1?${params}`);
  };
  const handleFlightStatusSubmit = (data: any) => {
    const { SearchBy, FlightNumber, DepartDate, From, To } = data.data;
    const url =
      SearchBy === 'Flight number'
        ? `${envConfig.bookingHost}/flight-status/?searchByFlightNum&flightNum=${FlightNumber}&DepartDate=${format(DepartDate[0], 'yyyy-MM-dd')}`
        : `${envConfig.bookingHost}/flight-status/?searchByRoute&From=${From}&To=${To}`;

    router.push(url);
  };

  const i18nContent = {
    booking: {
      bookATrip: t('web.home.bookATrip'),
      manageMyBooking: t('web.home.manageMyBooking'),
      checkIn: t('web.home.checkIn'),
      flightStatus: t('web.home.flightStatus'),
    },
    bookTrip: {
      trip: t('web.home.bookATrip.trip'),
      passengers: t('web.home.bookATrip.passengers'),
      passengersAdult: t('web.home.bookATrip.passengers.adult'),
      passengersChild: t('web.home.bookATrip.passengers.child'),
      passengersChildTip: t('web.home.bookATrip.passengers.childTip'),
      passengersInfant: t('web.home.bookATrip.passengers.infant'),
      passengersInfantTip: t('web.home.bookATrip.passengers.infantTip'),
      passengersPopUpTitle: t('web.home.bookATrip.passengers.popUpTitle'),
      promoCodeOptional: t('web.home.bookATrip.promoCodeOptional'),
      date: t('web.home.bookATrip.date'),
      chooseTravelDates: t('web.home.bookATrip.chooseTravelDates'),
      departDate: t('web.home.bookATrip.departDate'),
      returnDate: t('web.home.bookATrip.returnDate'),
      departDateTrip1: t('web.home.bookATrip.departDateTrip1'),
      departDateTrip2: t('web.home.bookATrip.departDateTrip2'),
      search: t('web.home.bookATrip.search'),
      recentSearches: t('web.home.bookATrip.recentSearches'),
      multiCity: t('web.home.bookATrip.multiCity'),
      tripOptions: {
        roundTrip: t('web.home.bookATrip.roundTrip'),
        oneWay: t('web.home.bookATrip.oneWay'),
        multiCity: t('web.home.bookATrip.multiCity'),
      },
      fromRequired: t('web.home.bookATrip.from.required'),
      toRequired: t('web.home.bookATrip.from.toRequired'),
      departDateRequired: t('web.home.bookATrip.departDate.required'),
      returnDateRequired: t('web.home.bookATrip.returnDate.required'),
      multiCityFrom1Required: t('web.home.bookATrip.multiCityFrom1.required'),
      multiCityTo1Required: t('web.home.bookATrip.multiCityTo1.required'),
      multiCityDepartDate1Required: t('web.home.bookATrip.multiCityDepartDate.required'),
      multiCityFrom2Required: t('web.home.bookATrip.multiCityFrom2.required'),
      multiCityTo2Required: t('web.home.bookATrip.multiCityTo2.required'),
      multiCityDepartDate2Required: t('web.home.bookATrip.multiCityReturnDate.required'),
      from: t('web.home.bookATrip.from'),
      to: t('web.home.bookATrip.to'),
      airportCode: {
        HK: t('marketCodeName.HK'),
        HKG: t('airportCodeToCityName.HKG'),
        CN: t('marketCodeName.CN'),
        FYG: t('airportCodeToCityName.FYG'),
        NGB: t('airportCodeToCityName.NGB'),
        NSZ: t('airportCodeToCityName.NSZ'),
        PFT: t('airportCodeToCityName.PFT'),
        PKX: t('airportCodeToCityName.PKX'),
        SYX: t('airportCodeToCityName.SYX'),
        ZGN: t('airportCodeToCityName.ZGN'),
        ZTI: t('airportCodeToCityName.ZTI'),
        ZYK: t('airportCodeToCityName.ZYK'),
        JP: t('marketCodeName.JP'),
        FUK: t('airportCodeToCityName.FUK'),
        HND: t('airportCodeToCityName.HND'),
        KIX: t('airportCodeToCityName.KIX'),
        KMJ: t('airportCodeToCityName.KMJ'),
        KOJ: t('airportCodeToCityName.KOJ'),
        NGO: t('airportCodeToCityName.NGO'),
        OKA: t('airportCodeToCityName.OKA'),
        NRT: t('airportCodeToCityName.NRT'),
        TAK: t('airportCodeToCityName.TAK'),
        TYO: t('airportCodeToCityName.TYO'),
        KR: t('marketCodeName.KR'),
        CJU: t('airportCodeToCityName.CJU'),
        ICN: t('airportCodeToCityName.ICN'),
        PUS: t('airportCodeToCityName.PUS'),
        PH: t('marketCodeName.PH'),
        TH: t('marketCodeName.TH'),
        BK1: t('airportCodeToCityName.BK1'),
        BKK: t('airportCodeToCityName.BKK'),
        KHH: t('airportCodeToCityName.KHH'),
        DMK: t('airportCodeToCityName.DMK'),
        CMX: t('airportCodeToCityName.CMX'),
        HKT: t('airportCodeToCityName.HKT'),
        TW: t('marketCodeName.TW'),
        RMQ: t('airportCodeToCityName.RMQ'),
        TPE: t('airportCodeToCityName.TPE'),
        CRK: t('airportCodeToCityName.CRK'),
        MNL: t('airportCodeToCityName.MNL'),
        CNX: t('airportCodeToCityName.CNX'),
        VN: t('marketCodeName.VN'),
        CXR: t('airportCodeToCityName.CXR'),
        DAD: t('airportCodeToCityName.DAD'),
        HAN: t('airportCodeToCityName.HAN'),
        MY: t('marketCodeName.MY'),
        CZX: t('airportCodeToCityName.CZX'),
        HZI: t('airportCodeToCityName.HZI'),
        YIW: t('airportCodeToCityName.YIW'),
        KIJ: t('airportCodeToCityName.KIJ'),
        HUN: t('airportCodeToCityName.HUN'),
        PEN: t('airportCodeToCityName.PEN'),
        KH: t('marketCodeName.KH'),
        MP: t('marketCodeName.MP'),
        SG: t('marketCodeName.SG'),
      },
    },
    manageBooking: {
      bookingReferenceRequired: t('web.home.bookingReference.required'),
      lastNameRequired: t('web.home.lastName.required'),
      firstNameRequired: t('web.home.firstName.required'),
      text: t('web.home.manageBooking.text'),
      bookingReference: t('web.home.manageBooking.bookingReference'),
      lastName: t('web.home.manageBooking.lastName'),
      firstName: t('web.home.manageBooking.firstName'),
      manageBooking: t('web.home.manageBooking.manageBooking'),
      retrieveMyTrip: t('web.home.manageBooking.retrieveMyTrip'),
    },
    flightStatus: {
      flightNumber: t('web.home.flightStatus.flightNumber'),
      route: t('web.home.flightStatus.route'),
      text: t('web.home.flightStatus.text'),
      fromRequired: t('web.home.flightStatus.from.required'),
      toRequired: t('web.home.flightStatus.to.required'),
      flightNumberRequired: t('web.home.flightStatus.flightNumber.required'),
      departDateRequired: t('web.home.flightStatus.departDate.required'),
      departDate: t('web.home.flightStatus.departDate'),
      searchBy: t('web.home.flightStatus.searchBy'),
      search: t('web.home.flightStatus.search'),
    },
    checkIn: {
      bookingReferenceRequired: t('web.home.bookingReference.required'),
      lastNameRequired: t('web.home.lastName.required'),
      firstNameRequired: t('web.home.firstName.required'),
      text: t('web.home.checkIn.text'),
      bookingReference: t('web.home.checkIn.bookingReference'),
      lastName: t('web.home.checkIn.lastName'),
      firstName: t('web.home.checkIn.firstName'),
      checkIn: t('web.home.checkIn.checkIn'),
    },
  };

  return (
    <Box className={`${params?.styles}`}>
      <BookComponent
        handleBookingTripSubmit={handleBookingTripSubmit}
        handleManageMyBookingSubmit={handleManageMyBookingSubmit}
        handleManageMyBookingLink={handleManageMyBookingLink}
        handleCheckInSubmit={handleCheckInSubmit}
        handleFlightStatusSubmit={handleFlightStatusSubmit}
        flightRouteOptions={flightRouteOptions}
        i18nContent={i18nContent}
        campaignCode={params.campaignCode || ''}
      />
    </Box>
  );
};

export default withDatasourceCheck()<BookingProps>(Booking);
